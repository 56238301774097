@if(isReportingV2$ | async){
<div class="flex items-center justify-between pb-3 gap-4 text-sm">
    @if (dashboardHeaderData$ | async; as details) {
      <div class="flex items-center gap-3 flex-1">
        <div class="space-y-0 5">
            <div class="flex items-center gap-3">
                <h3 class="font-medium text-xl leading-none pr-3 font-title dark:text-white/80">
                    {{ details.title | translate }}
                </h3>
                <div class="flex items-center gap-2">
                    <!-- Pinning button -->
                    <app-overlay-popup [isCustom]="true" label="overlay">
                        <button class="p-1.5 rounded hover:bg-white dark:hover:bg-slate-900 active"
                            (click)="details.isFavourite ? removeBookmarkedReport(details.title) : bookmarkReport(details.title)">
                            <app-svg-images 
                                class="h-4.5 text-amber-500 flex"
                                [svgName]="details.isFavourite ? 'bookmark-solid' : 'bookmark'"
                                [ngClass]="{ 'opacity-70 dark:text-amber-200': !details.isFavourite}">
                            </app-svg-images>
                        </button>
                        <div popup-content class="z-10 px-2.5 py-1 w-full inline-flex flex-col items-center justify-center subpixel-antialiased outline-none box-border text-small bg-white rounded-md shadow-md">
                            <div class="p-1 text-center">
                                {{ (details.isFavourite ? 'product.bookmark.unpin' : 'product.bookmark.pin') | translate }}
                            </div>
                        </div>
                    </app-overlay-popup>
                    @if ((details.description | translate)?.length > 0) {
                        <!-- Info button -->
                        <app-overlay-popup [isCustom]="true" label="overlay" position="right-center">
                            <button class="p-1.5 rounded-md hover:shadow-sm hover:bg-soft-blue dark:hover:bg-slate-900 active">
                                <app-svg-images svgName="question-mark-circle" class="h-4.5 text-gray-400 dark:text-white/80 flex"></app-svg-images>
                            </button>
                            <div popup-content class="z-10 px-2.5 py-1 w-full inline-flex flex-col items-center justify-center subpixel-antialiased outline-none box-border text-small bg-white rounded-md shadow-md">
                                <div class="p-1 text-center">
                                    {{ details.description | translate }}
                                </div>
                            </div>
                        </app-overlay-popup>
                    }
                </div>
            </div>
        </div>
      </div>
    }
    <div class="flex items-center gap-2 justify-end text-[13px]">
      <ng-content></ng-content>
    </div>
  </div>
}