import { AsyncPipe, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { HeaderDetails, ProductHeaderComponent } from 'src/app/core/ui/product-header/product-header.component';
import { SvgImagesComponent } from "../../../../core/ui/svg-images/svg-images.component";
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPopupComponent } from 'src/app/core/ui/overlay-popup/overlay-popup.component';
import { combineLatest, map, Observable } from 'rxjs';
import { selectReportPageHasFavourite } from 'src/app/store/selectors/reporting.selectors';
import { ReportingActions } from 'src/app/store/actions/reporting.actions';
import { selectIsReportingV2 } from 'src/app/store';

@Component({
  selector: 'app-product-dashboard-header',
  imports: [NgClass, AsyncPipe, SvgImagesComponent, TranslateModule, OverlayPopupComponent],
  standalone: true,
  templateUrl: './product-dashboard-header.component.html',
  styles: `
    :host {
      display: block;
    }
  `
})
export class ProductDashboardHeaderComponent extends ProductHeaderComponent implements OnInit {

  protected dashboardHeaderData$: Observable<HeaderDetails & {isFavourite: boolean}>;
  protected isReportingV2$: Observable<boolean>;

  ngOnInit(): void {
    super.ngOnInit();
    this.dashboardHeaderData$ = this.composeHeaderData$();
    this.isReportingV2$ = this.store.select(selectIsReportingV2);
  }

  protected bookmarkReport(label: string): void {
    this.store.dispatch(ReportingActions.addFavourite({ label, value: { label } }));
  }

  protected removeBookmarkedReport(label: string): void {
    this.store.dispatch(ReportingActions.deleteFavourite({ label }));
  }

  private composeHeaderData$(): Observable<HeaderDetails & {isFavourite: boolean}> {
    return combineLatest([
      this.headerDetails$,
      this.store.select(selectReportPageHasFavourite)
    ]).pipe(
      map(([headerDetails, isFavourite]) => ({ ...headerDetails, isFavourite }))
    );
  }
}
